<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 173px; width:411px">
        <field
          name="codigo"
          widget="input"
          label="Código"
          placeholder="Cód"
		  help="Cód. de la Delegación"
          width="50px"
          required
          inputStyle="font-weight:bold; text-align:center;"
          style="top:9px; left:30px;"
          searchable
          :readonly="mode=='edit'"
        />
        <field
          name="nombre"
          widget="input"
          searchable
          label="Delegación"
          help="Descripción de la Delegación"
          placeholder="Descripción de la Delegación"
          width="270px"
          style="top:9px; left:105px;"
        />
        <field
          name="almacenes"
          widget="handsontable"
          :height="110"
          :width="350"
          style="top:53px; left:30px;"
          :minRows="3"
          :columns="[
            {name:'codigo',header:'Código', readOnly:true},
            {name:'nombre',header:'Almacén'},
          ]"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Delegaciones",
      primary: "codigo",
      dbAdapter: "delegacion",
      sequence: { name: "delegacion" },
      defaultData: {
        empresa: 1
      }
    };
  }
};
</script>